import axios from '@/plugins/axios'
import { getAccountId } from '@/helper/auth-helper'

const getCampaigns = params => axios.get(`${getAccountId()}/campaigns/paginated`, { params })
const getAllCampaigns = () => axios.get(`${getAccountId()}/campaigns/all`)
const getCampaignOptions = () => axios.get('/campaigns/options')
const getDetailCampaign = campaignId => axios.get(`${getAccountId()}/campaigns/${campaignId}`)
const getCampaignManage = campaignId => axios.get(`${getAccountId()}/campaigns/${campaignId}/manage`)
const createCampaign = formData => axios.post(`${getAccountId()}/campaigns`, formData)
const cloneCampaign = formData => axios.post(`${getAccountId()}/campaigns/clone`, formData)
const uploadMusicOnHoldCampaign = ({ campaignId, formData, config }) => axios.post(`${getAccountId()}/campaigns/${campaignId}/file`, formData, config)
const updateCampaign = ({ campaignId, formData }) => axios.patch(`${getAccountId()}/campaigns/${campaignId}`, formData)
const changeCampaignStatus = ({ campaignId, formData }) => axios.patch(`${getAccountId()}/campaigns/${campaignId}/status`, formData)
const deleteCampaign = campaignId => axios.delete(`${getAccountId()}/campaigns/${campaignId}`)

const attachVendorToCampaign = ({ campaignId, formData }) => axios.patch(`${getAccountId()}/campaigns/${campaignId}/publishers`, formData)
const editAttachedVendor = ({ campaignId, formData }) => axios.put(`${getAccountId()}/campaigns/${campaignId}/publishers`, formData)
const detachVendorFromCampaign = ({ campaignId, params }) => axios.delete(`${getAccountId()}/campaigns/${campaignId}/publishers`, { params })

const attachNumberToCampaign = ({ campaignId, formData }) => axios.patch(`${getAccountId()}/campaigns/${campaignId}/numbers`, formData)
const detachNumbersFromCampaign = ({ campaignId, params }) => axios.delete(`${getAccountId()}/campaigns/${campaignId}/numbers`, { params })

const attachDestinationToCampaign = ({ campaignId, formData }) => axios.patch(`${getAccountId()}/campaigns/${campaignId}/targets`, formData)
const editAttachedDestination = ({ campaignId, formData }) => axios.put(`${getAccountId()}/campaigns/${campaignId}/targets`, formData)
const detachDestinationFromCampaign = ({ campaignId, params }) => axios.delete(`${getAccountId()}/campaigns/${campaignId}/targets`, { params })

const attachGroupToCampaign = ({ campaignId, formData }) => axios.patch(`${getAccountId()}/campaigns/${campaignId}/target-groups`, formData)
const editAttachedGroup = ({ campaignId, formData }) => axios.put(`${getAccountId()}/campaigns/${campaignId}/target-groups`, formData)
const detachGroupFromCampaign = ({ campaignId, params }) => axios.delete(`${getAccountId()}/campaigns/${campaignId}/target-groups`, { params })

const attachUrlParamsToCampaign = ({ campaignId, formData }) => axios.post(`${getAccountId()}/campaigns/${campaignId}/url-params`, formData)
const detachUrlParamsFromCampaign = ({ campaignId, params }) => axios.delete(`${getAccountId()}/campaigns/${campaignId}/url-params`, { params })

const attachPixelToCampaign = ({ campaignId, formData }) => axios.post(`${getAccountId()}/campaigns/${campaignId}/pixels`, formData)
const detachPixelFromCampaign = ({ campaignId, params }) => axios.delete(`${getAccountId()}/campaigns/${campaignId}/pixels`, { params })

const attachIvrToCampaign = ({ campaignId, ivrId }) => axios.patch(`${getAccountId()}/campaigns/${campaignId}/ivr/${ivrId}`)
const detachIvrFromCampaign = campaignId => axios.delete(`${getAccountId()}/campaigns/${campaignId}/ivr`)

export {
  getCampaigns, getAllCampaigns, getCampaignOptions, getDetailCampaign, getCampaignManage, createCampaign, cloneCampaign, uploadMusicOnHoldCampaign, updateCampaign, changeCampaignStatus, deleteCampaign, attachVendorToCampaign, editAttachedVendor, detachVendorFromCampaign, attachNumberToCampaign, detachNumbersFromCampaign, attachDestinationToCampaign, editAttachedDestination, detachDestinationFromCampaign, attachGroupToCampaign, editAttachedGroup, detachGroupFromCampaign, attachUrlParamsToCampaign, detachUrlParamsFromCampaign, attachPixelToCampaign, detachPixelFromCampaign, attachIvrToCampaign, detachIvrFromCampaign
}